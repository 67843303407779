import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { ElementRef, ViewChild } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

import { prepareScene, loadGlb } from 'js-3d-model-viewer';

const DETAILS: any = {
    '1': {
        title: "무령왕릉 디오라마",
        glb: '1.glb',
        images: ['1-1.jpeg', '1-2.jpeg']
    },
    '2.1': {
        title: "무령왕릉석수 (武寧王陵石獸)",
        glb: '2.1.glb',
        images: ['2.1-1.jpeg', '2.1-2.jpeg', '2.1-3.jpeg', '2.1-4.jpeg']
    },
    '2.2': {
        title: "흑갈유사이병 (黑褐釉四耳甁)",
        glb: '2.2.glb',
        images: ['2.2.jpeg']
    },
    '2.3': {
        title: "청자육이호 (靑磁六耳壺)",
        glb: '2.3.glb',
        images: ['2.3.jpeg']
    },
    '2.4': {
        title: "흑유계수호 (黑釉鷄首壺)",
        glb: '2.4.glb',
        images: ['2.4.jpeg']
    },
    '3.1': {
        title: '손오묘 (孫吳墓)',
        glb: '3.1.glb',
        images: ['3.1.jpeg']
    }
};

const ITEMS: any = [
    {
        title: '무령왕릉과 백제의 국제적 문화교류',
        child: [
            {
                target: '1',
                title: '무령왕릉 디오라마',
                desc: '백제(百濟, B.C. 18-A.D. 660) 무령왕(武寧王, 재위 501-523)의 무덤. 공주 왕릉원과 함께 웅진 백제시기의 왕릉으로서 무덤의 구조와 유물이 고대 동아시아의 문화교류를 보여주는 중요한 유적입니다.',
                src: '1-1.jpeg'
            },
            {
                target: '3.1',
                title: '손오묘 (孫吳墓)',
                desc: '3세기 촉조된 남조(孫吳)의 전형적인 벽돌 무덤. 중국 삼국시대(三國時代, 220-280)\n오나라(吳, 229-280) 황제의 친족 무덤으로 추정되며, 손오묘의 묘지벽들과\n도자기 등의 출토품이 무령왕릉 출토품과 유사하다는 점에서\n백제의 국제적 문화교류를 보여줍니다.',
                src: '3.1.jpeg'
            }
        ]
    },
    {
        title: '무령왕릉 출토품',
        child: [
            {
                target: '2.1',
                title: '무령왕릉석수 (武寧王陵石獸)',
                src: '2.1-1.jpeg',
                desc: '백제(百濟, B.C. 18-A.D. 660)\n높이 30cm, 길이 47.3cm, 너비 22cm\n무령왕릉 출토, 국립공주박물관'
            },
            {
                target: '2.2',
                title: '흑갈유사이병 (黑褐釉四耳甁)',
                src: '2.2.jpeg',
                desc: '중국 남조(南朝, 429-589)\n높이 27.5cm, 입지름 11.8cm\n무령왕릉 출토, 국립공주박물관'
            },
            {
                target: '2.3',
                title: '청자육이호 (靑磁六耳壺)',
                src: '2.3.jpeg',
                desc: '중국 남조(南朝, 420-589)\n높이 21.7cm, 입지름 11.3cm\n무령왕릉 출토, 국립공주박물관'
            },
            {
                target: '2.4',
                title: '흑유계수호 (黑釉鷄首壺)',
                desc: '중국 남조(南朝, 429-589)\n높이 27.5cm, 입지름 11.8cm\n무령왕릉 출토, 국립공주박물관',
                src: '2.4.jpeg'
            }
        ]
    }
];

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/ku/project/main/build/src/app/page.main/view.scss */
.video-container-portrait {
  width: auto;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 54/86;
}

.video-container-landscape {
  width: auto;
  height: 100vh;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 86/54;
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
  height: 100%;
  object-fit: cover;
}`],
})
export class PageMainComponent implements OnInit {
    @ViewChild('viewer') viewer: ElementRef;

    public view: any = 'list';

    public items: any = ITEMS;
    public selected: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async home() {
        this.view = 'list';
        this.selected = null;
        await this.service.render();
    }

    public async clickItem(item) {
        let target = DETAILS[item.target];
        this.view = 'item';
        this.selected = target;
        await this.service.render();

        const opts = {
            grid: false,
            trackball: false,
            background: 'rgba(232, 232, 232)'
            // background: 'rgb(255, 255, 255)'
        }
        const scene = prepareScene(this.viewer.nativeElement, opts)
        loadGlb(scene, `/assets/3d/${target.glb}`);
    }

    public chatbotUrl(selected) {
        return 'https://baekje.ask.season.co.kr/?page=' + encodeURIComponent(selected.title);
    }

}

export default PageMainComponent;